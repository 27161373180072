export const contentfulSettings = {
  giftWrappingTogether: "variant_01FM7RADS4VBNDCWX5K69HMNXR",
  giftWrappingIndividual: "variant_01FM7RADS1RA6W20T9REGX81AW",
  cartGiftingCollectionWrappingDescription:
    "All our gift sets arrive wrapped in Tekla gift wrapping",
  newsletterTitle: "Keep in touch",
  newsletterImage: {
    src: "https://tekla.b-cdn.net/4mKlPDSC4Rz6CWJlGyj3ob/3522a91f4e312764750f40c5714865d8/Popup23.jpg",
    srcSet: [
      "https://tekla.b-cdn.net/4mKlPDSC4Rz6CWJlGyj3ob/3522a91f4e312764750f40c5714865d8/Popup23.jpg?w=750&h=469&fl=progressive&q=50&fm=jpg 750w",
      "https://tekla.b-cdn.net/4mKlPDSC4Rz6CWJlGyj3ob/3522a91f4e312764750f40c5714865d8/Popup23.jpg?w=1080&h=675&fl=progressive&q=50&fm=jpg 1080w",
      "https://tekla.b-cdn.net/4mKlPDSC4Rz6CWJlGyj3ob/3522a91f4e312764750f40c5714865d8/Popup23.jpg?w=1366&h=854&fl=progressive&q=50&fm=jpg 1366w",
      "https://tekla.b-cdn.net/4mKlPDSC4Rz6CWJlGyj3ob/3522a91f4e312764750f40c5714865d8/Popup23.jpg?w=1920&h=1200&fl=progressive&q=50&fm=jpg 1920w",
    ],
  },
}
